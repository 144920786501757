<template>
    <vue-table-card :showSearchBox="false" class="m-3 c-table" title="Facility" :fields="fields" :url="listUrl"
                    :per-page="5"
                    ref="table">
        <template #buttons>
            <btn icon="fa fa-plus" size="sm" @click="showAddModal" text="Add"/>
        </template>
        <template #actions="{rowData}">
            <div class="btn-group-tight">
                <delete-btn color="primary" text="Edit" icon="" size="xs" @click="setEdit(rowData)"/>
                <delete-btn icon="" size="xs" @click="setDelete(rowData)"/>
            </div>
        </template>
        <template #status="{rowData}">
            <p v-if="rowData.status==='published'">Published</p>
            <p v-else>UnPublished</p>
        </template>

        <template #footer>
            <modal no-close-on-backdrop title="Add Facility" ref="addModal" width="30r" header-color="primary">
                <s-form @submit="submitClicked('add')" ref="form">
                    <validated-input label="Title" name="Title" v-model="model.title" :rules="{required : true}"
                                     :disabled="loading"/>
                    <validated-file-input class="c-file-input" label="Image" name="Image"
                                          v-model="model.file" :disabled="loading"/>
                    <validated-input label="Rank" name="Rank" v-model="model.rank" :rules="{required : true}"
                                     :disabled="loading" type="number"/>
                    <validated-vue-select label="Status" name="Status" v-model="model.status"
                                          :rules="{required : true}" :options="statusOption"
                                          :disabled="loading" />
                    <btn size="sm" text="Save" :loading="loading" :disabled="loading" loading-text="Saving.."/>
                </s-form>
            </modal>

            <modal no-close-on-backdrop title="Edit Facility" ref="editModal" width="30r" header-color="primary">
                <s-form @submit="submitClicked('edit')" ref="form">
                    <validated-input label="Title" name="Title" v-model="model.title" :rules="{required : true}"
                                     :disabled="loading"/>
                    <validated-file-input class="c-file-input" label="Image" name="Image"
                                          v-model="model.file" :disabled="loading"/>
                    <validated-input label="Rank" name="Rank" v-model="model.rank" :rules="{required : true}"
                                     :disabled="loading" type="number"/>
                    <validated-vue-select label="Status" name="Status" v-model="model.status"
                                          :rules="{required : true}" :options="statusOption"
                                          :disabled="loading" />
                    <btn size="sm" text="Update" :loading="loading" :disabled="loading" loading-text="Updating.."/>
                </s-form>
            </modal>

            <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteSuccess">
                <p>You are about to delete the item. Are you sure?</p>
                <template #loading>
                    <loading-animation/>
                    Please wait while we delete the item.
                </template>
            </delete-modal>
            <modal ref="confirmModal" title="Confirm" class="text-center" width="30r"
                   no-close-on-backdrop>
                <p class="text-left">The rank you entered already exists, Do you wish to replace the existing facility ?</p>
                <div class="fl-x-br">
                    <btn size="xs" @click="swapValue()" class="w-20p ml-2" text="Ok"/>
                </div>
            </modal>
        </template>

    </vue-table-card>
</template>

<script>
// import urls from '@/data/urls';
import axios from 'secure-axios';
import urls from '../../data/urls';

export default {
    name: 'FacilityListing',
    data () {
        return {
            loading: false,
            listUrl: urls.admin.facility.list,
            deleteUrl: urls.admin.facility.delete,
            addUrl: urls.admin.facility.add,
            editUrl: urls.admin.facility.edit,
            statusOption: [
                { value: 'published', label: 'Published' },
                { value: 'unpublished', label: 'UnPublished' }
            ],
            deletingItem: {
                id: ''
            },
            fields: [
                {
                    name: 'slno',
                    title: 'No'
                },
                {
                    name: 'title',
                    title: 'Title'
                },
                {
                    name: 'rank',
                    title: 'Rank'
                },
                {
                    name: '__slot:status',
                    title: 'Status'
                },
                {
                    name: '__slot:actions',
                    titleClass: 'center aligned text-right',
                    dataClass: 'aligned text-right',
                    title: 'Actions'
                }
            ],
            model: {
                name: ''
            },
            actionClicked: ''
        };
    },
    methods: {
        showAddModal () {
            this.confirm = false;
            this.model = {};
            this.$refs.addModal.show();
        },
        setEdit (item) {
            this.confirm = false;
            this.model = { ...item };
            this.$refs.editModal.show();
        },
        setDelete (item) {
            this.deletingItem.id = item.id;
            this.$refs.deleteModal.show();
        },
        async submitClicked (action) {
            const that = this;
            that.model.interchange = that.confirm;
            that.loading = true;
            action = that.setAction(action);
            const response = await axios.form(that.action, that.model);
            const json = response.data;
            if (json.error === false) {
                that.selectSuccess(action);
                that.loading = false;
            } else {
                that.formError(json, action);
                that.loading = false;
            }
            that.loading = false;
        },
        setAction (action) {
            if (action === '') {
                action = this.actionClicked;
            }
            if (action === 'add') {
                this.action = this.addUrl;
            }
            if (action === 'edit') {
                this.action = this.editUrl;
            }
            return action;
        },
        selectSuccess (action) {
            if (action === 'add') {
                this.formSuccess();
            }
            if (action === 'edit') {
                this.formSuccess1();
            }
            if (action === undefined) {
                this.formSuccess1();
            }
        },
        formSuccess () {
            this.$refs.addModal.close();
            this.$refs.editModal.close();
            this.$notify('Facility Saved Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        },
        formSuccess1 () {
            this.$refs.addModal.close();
            this.$refs.editModal.close();
            this.$notify('Facility Updated Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        },
        deleteSuccess () {
            this.$refs.deleteModal.close();
            this.deletingItem.id = '';
            this.$notify('Deleted Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        },
        formError (json, action) {
            if (json.errors.Rank === 'Rank already exists') {
                this.actionClicked = action;
                this.$refs.confirmModal.show();
            }
            this.$refs.form.setErrors(json.errors);
        },
        swapValue () {
            this.confirm = true;
            this.$refs.confirmModal.close();
            this.submitClicked();
        }
    }
};
</script>

<style scoped>

</style>
